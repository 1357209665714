'use strict';
import mainApi from '@/assets/js/api/modules/main';
import axios from 'axios';

export default {
  namespaced: true,
  state: {
    user_list: {},
    user_details: {},
    branches_details: {},
    group_permission: {},
  },
  getters: {
    getUserList: (state) => state.user_list,
    getUserDetails: (state) => state.user_details,
    getAllBranches: (state) => state.branches_details,
    getAllGroupPermission: (state) => state.group_permission,
  },
  mutations: {
    SET_USER_LIST(state, payload) {
      state.user_list = payload;
    },
    SET_USER_DETAILS(state, payload) {
      state.user_details = payload;
    },
    SET_USER_BRANCHES_LIST(state, payload) {
      state.branches_details = payload;
    },
    SET_GROUP_PERMISSION(state, payload) {
      state.group_permission = payload;
    },
    CLEAR_STATE(state) {
      state.user_list = {};
      state.user_details = {};
    },
  },
  actions: {
    fetchUsers(context, payload) {
      const { config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi
          .getData({ url: '/users', config, apiCancelToken })
          .then((response) => {
            // console.log(response.data)
            context.commit('SET_USER_LIST', response.data);
            resolve(response);
          })
          .catch((error) => {
            
            context.commit('SET_USER_LIST', {});
            context.dispatch('error', error, { root: true });
            reject(error);
          });
      });
    },
    fetchBranches(context, payload) {
      
      const { config, apiCancelToken } = payload || {};
      
      return new Promise((resolve, reject) => {
        mainApi
          .getData({ url: '/users/branches', config, apiCancelToken })
          .then((response) => {
            
            context.commit('SET_USER_BRANCHES_LIST', response.data);
            resolve(response);
          })
          .catch((error) => {
            
            context.commit('SET_USER_BRANCHES_LIST', {});
            context.dispatch('error', error, { root: true });
            reject(error);
          });
      });
    },
    fetchBranches2(context, payload) {
      
      const { config, apiCancelToken } = payload || {};
      
      return new Promise((resolve, reject) => {
        mainApi
          .getData({ url: '/users/branches', config, apiCancelToken })
          .then((response) => {
            
            //context.commit('SET_USER_BRANCHES_LIST', response.data);
            resolve(response);
          })
          .catch((error) => {
            
            //context.commit('SET_USER_BRANCHES_LIST', {});
            context.dispatch('error', error, { root: true });
            reject(error);
          });
      });
    },
    findUser(context, payload) {
      
      const { id, config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi
          .getData({ url: `/users/${id}`, config, apiCancelToken })
          .then((response) => {
            
            //context.commit('SET_USER_DETAILS', response.data);
            resolve(response);
          })
          .catch((error) => {
            
            //context.commit('SET_USER_DETAILS', {});
            context.dispatch('error', error, { root: true });
            reject(error);
          });
      });
    },
    addUserCSV(context, payload) {
      const { data, config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi
          .postData({ url: '/rider/upload', data, config, apiCancelToken })
          .then((response) => {
            
            resolve(response);
          })
          .catch((error) => {
            
            context.dispatch('error', error, { root: true });
            reject(error);
          });
      });
    },
    addUser(context, payload) {
      
      const { data, config, apiCancelToken } = payload || {};
      
      return new Promise((resolve, reject) => {
        mainApi
          .postData({ url: '/users', data, config, apiCancelToken })
          .then((response) => {
            
            resolve(response);
          })
          .catch((error) => {
            
            context.dispatch('error', error, { root: true });
            reject(error);
          });
      });
    },
    addMultipleUser(context, payload) {
      const { data, config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi
          .postData({ url: '/rider/batch', data, config, apiCancelToken })
          .then((response) => {
            
            resolve(response);
          })
          .catch((error) => {
            
            context.dispatch('error', error, { root: true });
            reject(error);
          });
      });
    },
    setUser(context, payload) {
      const { userId, data, config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi
          .putData({ url: `/users/${userId}`, data, config, apiCancelToken })
          .then((response) => {
            
            resolve(response);
          })
          .catch((error) => {
            
            context.dispatch('error', error, { root: true });
            reject(error);
          });
      });
    },
    updateUserStatus(context, payload) {
      const { userId, data, config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi
          .putData({ url: `/users/${userId}/status`, data, config, apiCancelToken })
          .then((response) => {
            
            resolve(response);
          })
          .catch((error) => {
            
            context.dispatch('error', error, { root: true });
            reject(error);
          });
      });
    },
    updateEmailMobile(context, payload) {
      const { userId, data, config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi
          .putData({ url: `/users/updateEmailMobile/${userId}`, data, config, apiCancelToken })
          .then((response) => {
            
            resolve(response);
          })
          .catch((error) => {
            
            context.dispatch('error', error, { root: true });
            reject(error);
          });
      });
    },
    deleteUser(context, payload) {
      const { config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi
          .deleteData({ url: '/rider', config, apiCancelToken })
          .then((response) => {
            
            resolve(response);
          })
          .catch((error) => {
            
            context.dispatch('error', error, { root: true });
            reject(error);
          });
      });
    },
    getGroupPermisssion(context, payload){
      
      const { config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi
          .getData({ url: '/users/group', config, apiCancelToken })
          .then((response) => {
            
            context.commit('SET_GROUP_PERMISSION', response.data);
            resolve(response);
          })
          .catch((error) => {
            
            context.commit('SET_GROUP_PERMISSION', {});
            context.dispatch('error', error, { root: true });
            reject(error);
          });
      });

    }
    
  },
};
